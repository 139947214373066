<template>
	<div id="check">
		<el-dialog title="新增考勤点" :visible.sync="visible" @close="goPage(0)" top="5%">
			<el-form :model="postdata" label-width="98px" class="clearfix" :rules="rules" ref="ruleForm">
				<el-form-item label="考勤点名称:" prop="checkname">
					<el-input v-model.trim="postdata.checkname" placeholder="请输入考勤点名称"></el-input>
				</el-form-item>
				<el-form-item label="考勤地址:" required prop="checkaddress">
					<el-input readonly="readonly" v-model.trim="postdata.checkaddress" placeholder="请输入考勤地址"></el-input>
				</el-form-item>
				<div class="madewrap">
					<div id="made"></div>
					<input type="text" ref="pickerInput" id="pickerInput" @blur="setAddress" placeholder="请输入搜索地址">
				</div>
				<el-form-item label="有限范围:" prop="checkrange">
					<sy-input type="int" v-model="postdata.checkrange" :min="1" :max="999" :style="{width: '140px'}" placeholder="请输入有限范围"></sy-input><span class="ml-10">米</span>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input v-model.trim="postdata.checkremark" :style="{width: '640px'}" placeholder="请输入备注"></el-input>
				</el-form-item>
				<div class="tac">
					<el-button type="danger" @click="goPage(1)">提交</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	var map
	var auto
	var geocoder
	var positionPicker
	export default {
		props: ["cVisible"],
		data() {
			return {
				aa: null,
				visible: false,
				postdata: {
					checkname: '',//考勤点名称
					checkaddress: '',//考勤点地点
					signOnLongitude: '',//经纬度
					signOnLatitude: '',//经纬度
					checkrange: '',//有限范围
					checkremark: '',//备注
				},
				rules: {
					checkname: [{
						required: true,
						message: "请输入考勤点名称"
					}],
					checkrange: [{
						required: true,
						message: "请输入有限范围"
					}],
					checkaddress: [{
						required: true,
						message: "请输入考勤地址"
					}]
				}
			}
		},
		methods: {
			goPage(type) {
				this.$refs.ruleForm.validate((valid) => {
					if(valid) {
						this.$emit('closedialog', type == 1 ? Object.assign({}, this.postdata) : "")
					} else {
						return false;
					}
				});
				if(type == 0) {
					this.$emit('closedialog')
					this.$refs.ruleForm.resetFields();
				}
			},
			setAddress(e){

			}
		},
		watch: {
			cVisible(val) {
				this.visible = val
				let that = this
				if(val) {
					//数据清空
					this.postdata = {
						checkname: '',
						checkaddress: '',
						signOnLongitude: '',
						signOnLatitude: '',
						checkrange: '',
						checkremark: ''
					}
					
					setTimeout(() => {
						// if(this.$refs.pickerInput){
						// 	this.$refs.pickerInput.value = ''
						// }
						//数据清空
						// map = new AMap.Map('made', {
						// 	zoom: 15
						// });
						map = new BMap.Map("made", {
								coordsType: 3, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
						});
						var point = new BMap.Point(116.404, 39.915);  // 创建点坐标  
						map.centerAndZoom(point, 15);  
						var ac = new BMap.Autocomplete(    //建立一个自动完成的对象
								{"input" : "pickerInput"
								,"location" : map
						});
						var myValue;
						ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
								var _value = e.item.value;
								myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
								setPlace();
						});
						function setPlace(){
							function myFun(){
									var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
									that.postdata.signOnLongitude= pp.lng,//经纬度
									that.postdata.signOnLatitude= pp.lat,
									that.postdata.checkaddress = local.getResults().getPoi(0).address
									map.centerAndZoom(pp, 18);
									map.clearOverlays()
									map.addOverlay(new BMap.Marker(pp));    //添加标注
							}
							var local = new BMap.LocalSearch(map, { //智能搜索
							onSearchComplete: myFun
							});
							local.search(myValue);
            }
						//在使用插件接口之前需要使用AMap.plugin方法将对应的功能加载下来，然后才能创建相应的对象
						// map.plugin('AMap.Geolocation', function() {
						// 	//配置定位,获取用户当前准确位置插件
						// 	var geolocation = new AMap.Geolocation({
						// 		enableHighAccuracy: true, //是否使用高精度定位，默认:true
						// 		timeout: 10000, //超过10秒后停止定位，默认：无穷大
						// 		maximumAge: 0, //定位结果缓存0毫秒，默认：0
						// 		convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
						// 		showButton: true, //显示定位按钮，默认：true
						// 		buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
						// 		buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
						// 		showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
						// 		showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
						// 		panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
						// 		zoomToAccuracy: true //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
						// 	});
						// 	////创建并添加定位工具条控件
						// 	map.addControl(geolocation);
						// 	//执行获取当前位置方法
						// 	geolocation.getCurrentPosition();
						// 	AMap.event.addListener(geolocation, 'complete', (res) => {
						// 		// console.log('定位成功',res,'res')
						// 		// that.oldadd = res.formattedAddress
						// 	}); //返回定位信息
						// 	AMap.event.addListener(geolocation, 'error', (err) => {
						// 		// console.log('定位失败',err,'err')
						// 		that.postdata.checkaddress = null;
						// 		that.postdata.signOnLongitude = null;
						// 		that.postdata.signOnLatitude = null;
						// 		that.oldadd = ''
						// 	}); //返回定位出错信息
						// });
						//工具条，控制地图的缩放、平移等
						// map.plugin('AMap.ToolBar', function() {
						// 	var toolBar = new AMap.ToolBar({
						// 		visible: true
						// 	})
						// 	map.addControl(toolBar);
						// })

						// setTimeout(() => {
						// 	var that = this
						// 	//加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
						// 	AMapUI.loadUI(['misc/PositionPicker'], function(PositionPicker) {
						// 		positionPicker = new PositionPicker({
						// 			mode: 'dragMap', //设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
						// 			map: map //依赖地图对象
						// 		});
						// 		//拖拽获取到有效地址回调
						// 		positionPicker.on('success', (res) => {
						// 			that.postdata.checkaddress = res.address;//拖拽定位的地址
						// 			that.postdata.signOnLongitude = res.position.lng
						// 			that.postdata.signOnLatitude = res.position.lat
						// 		})
						// 		//拖拽未获取到有效地址回调
						// 		positionPicker.on('fail', (err) => {
						// 			// that.oldadd = null
						// 			that.postdata.checkaddress = null;//拖拽定位的地址
						// 			that.postdata.signOnLongitude = null
						// 			that.postdata.signOnLatitude = null
						// 			//console.log('拖拽失败',err,'err')
						// 		})
						// 		//开启拖拽功能
						// 		positionPicker.start()
						// 		//TODO:事件绑定、结果处理等
						// 	});

						// 	if(this.$refs.pickerInput){
						// 		//加载PoiPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
						// 		AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {
						// 		  	var poiPicker = new PoiPicker({
						// 		      	input: 'pickerInput' //输入框id
						// 		  	});
						// 		  	//监听poi选中信息
						// 		  	poiPicker.on('poiPicked', function(poiResult) {
						// 		    	//用户选中的poi点信息
						// 		    	// console.log(poiResult,'poiresult')
						// 		    	that.$refs.pickerInput.value = poiResult.item.name
						// 		    	if(poiResult.item.location){
						// 		    		map.setZoomAndCenter(14,[poiResult.item.location.lng,poiResult.item.location.lat]);
						// 		    	}
						// 		  	});
						// 		});
						// 	}
						// }, 0)
					}, 0)
				}
			},
		}
	}
</script>
<style lang="less">
	#check {
		.el-dialog {
			width: 840px;
			.el-dialog__body {
				padding: 40px 30px;
				.el-input {
					width: 500px;
				}
				.madewrap{
					position: relative;
					width: 640px;
					height: 360px;
					margin-top: 10px;
					margin-bottom: 30px;
					margin-left: 98px;
					#made {
						width: 640px;
						height: 360px;
					}
					#pickerInput{
						position: absolute;
						right: 30px;
						top: 30px;
						z-index: 200;
						padding: 3px 10px;
						height: 24px;
						width: 140px;
						line-height: 24px;
						color: #666;
						border: 1px solid #dfdfdf;
					}
				}
			}
		}
	}
	.tangram-suggestion {
    z-index: 9999;
  }
</style>