import Vue from 'vue';
import axios from 'axios';
import VueLazyload from 'vue-lazyload'
//引入vuex的store
import store from './store/index';
import ElementUI from 'element-ui';
import '../theme/iconfont.css';
import '../theme/overwrite.less';
import '../theme/common.css';
import '../theme/common2.less';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router/index.js';
import { baseURI, basePath, httpHeaderConfig, axiosApi, gotoLoginPage, getLoginInfoBySessionId} from "~/api/api.js";
import registercomponent from './components/registercomponent';
import area from   './js/area';
import { deepClone } from './utils/deepClone';
import { debouncing } from './utils/debouncing';
import "./js/filter.js";
import "./js/extend.js";
import $ from 'jquery'
//引入echarts
import * as echarts from 'echarts'
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

import { PROJECT } from './config'; //引入配置文件
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.use(VueLazyload,{
    preLoad: 1.3,
    error: require('./assets/goods.jpg'),
    loading: require('./assets/goods.jpg')
  });
registercomponent.init();
area.init()
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.prototype.$http = axios
//挂载深拷贝
Vue.prototype.$deepClone = deepClone;
//挂载防抖阿函数
Vue.prototype.$debouncing = debouncing;

let ready = false;
Array.prototype.indexOf = function(val) {
    for (let i = 0; i < this.length; i++) {
        if (this[i] == val) return i;
    }
    return -1;
};
Array.prototype.remove = function(val) {
    let index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

const getEmployeeInfo = (loginName, successCallback, failCallback) => {
    let data = {
        "body": {
            "loginName": loginName,
            "appId":2
        },
        "ctrlData": {
            "pageIndex": 1,
            "pageSize": 10
        }
    };
    data = JSON.stringify(data);
    return (axios.post(`${baseURI}` + "/foundation/employee/selectOneByLoginName", data, httpHeaderConfig).then((res) => {
        switch (res.data.code) {
            case "000000": //系统交易成功
                successCallback(res);
                break;
            default:
                failCallback();
                break;
        }
    }).catch((err) => {
        failCallback();
    }))
};
const gotoPageWithSession = (to, next) => {
    if (to.path == basePath + '/login') {
        next({
            path: basePath + '/shop/shopIndex'
        });
    } else {
        next();
        ready = true;
    }
};
const gotoPageWithoutSession = (to, next) => {
    if (to.path == basePath + '/shop/shopIndex' || to.path.indexOf('/shop/goodsDetails') >= 0 || to.path.indexOf('/goodsSearch') >= 0 || to.path.indexOf('/aggrement') >= 0 || to.path.indexOf('/storeMap') >= 0) {
        next({
            path: to.path,
            query: {
                isForce: true
            }
        });
    } else {
        gotoLoginPage();
    }
};

const forbidList = ['/shop/shopIndex','/goodsSearch','/shop/shopingCar','/shop/goodsDetails'];

router.beforeEach((to, from, next) => {
    if(to.path=='/redirect'){
        next()
    }else if(to.query.JSESSIONID && to.query.JSESSIONID != 'null'){
        getLoginInfoBySessionId(to.query.JSESSIONID, null, next)
    }else{
        getLoginInfoBySessionId(null, null, next)
    }
})

router.afterEach(() => {
	if(ready && window.site)window.site();
})

const m = new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
})

export default m
