import GroupProduct from '../views/superGroup/groupProduct.vue' //超团商品
import GroupDetails from '../views/superGroup/groupDetails.vue' //商品详情，基本信息
import GroupOrder from '../views/superGroup/groupOrder.vue' //超团订单
import GroupConsignment from '../views/superGroup/groupConsignment.vue' //超团发货
import GroupOrderDetails from '../views/superGroup/groupOrderDetails.vue' //超团订单详情
import GroupCompanyAudit from '../views/superGroup/groupCompanyAudit.vue' //企业审核
import GroupCompanyDetails from '../views/superGroup/groupCompanyDetails.vue' //企业审核详情
import GroupCommodityAudit from '../views/superGroup/groupCommodityAudit.vue' //下级商户商品审核
import GroupCommodityAuditDetails from '../views/superGroup/groupCommodityAuditDetails.vue' //下级商户商品审核详情
import FreightPrice from '../views/superGroup/freightPrice.vue' //费率结算
import GroupStatement from '../views/superGroup/groupStatement.vue' //超团报表
import GroupStatementDetail from '../views/superGroup/groupStatementDetail.vue' //超团报表详情
import Inventory from '../views/superGroup/inventory.vue' //超团库存
import SellerManager from '../views/superGroup/sellerManager.vue' //商家管理
import GroupManager from '../views/superGroup/groupManager.vue' //团购管理
import BondManager from '../views/superGroup/bondManager.vue' //保证金管理
import GroupSet from '../views/superGroup/groupSet.vue' //团购设置
import GroupAllGoods from '../views/superGroup/groupAllGoods.vue' //超团总商品
import GroupDetail from '../views/superGroup/groupDetail.vue' //团购详情
import GroupSalesManager from '../views/superGroup/groupSalesManager.vue' //营销管理
import AddActivity from '../views/superGroup/addActivity.vue' //添加管理
import ActivityDetail from '../views/superGroup/activityDetail.vue'//
import VoucherDetail from '../views/superGroup/voucherDetail.vue'//voucherDetail
import {basePath} from '../api/api'

export const ctFirstRouters = [];

export const ctHomeRouters = [
];
export const ctHomeLogoRouters = [];

export const ctHomeMenuRouters = [
    {
        path: basePath + '/groupOrderDetails', //超团订单详情
        component: GroupOrderDetails,
        name:'groupOrderDetails',
        text:'超团订单详情'
    },
    {
        path: basePath + '/groupCompanyDetails/:id?/:sign?', //超团企业审核详情
        component: GroupCompanyDetails,
        name:'groupCompanyDetails',
        text:'超团企业审核详情'
    },
    {
        path: basePath + '/groupCommodityAuditDetails/:skuNo?/:goodsType?/:rej?/:show?', //超团下级商户商品审核详情
        component: GroupCommodityAuditDetails,
        name:'groupCommodityAuditDetails',
        text:'超团下级商户商品审核详情'
    },
    {
        path: basePath + '/superGroup/addActivity',//添加活动
        component: AddActivity,
        name:'addActivity',
        text:'添加活动'
    },
    {
        path: basePath + '/superGroup/VoucherDetail',//优惠券详情
        component: VoucherDetail,
        name:'VoucherDetail',
        text:'优惠券详情'
    },
    {
        path: basePath + '/superGroup/activityDetail',//活动详情
        component: ActivityDetail,
        name:'activityDetail',
        text:'活动详情'
    },
    {
        path: basePath + '/groupDetails/:skuNo?/:goodsType?/:rej?/:addType?', //超团编辑商品主页
        component: GroupDetails,
        name:'groupDetails',
        text:'超团编辑商品主页'
    },
    {
        path: basePath + '/superGroup/groupProduct', //商品管理/全部商品
        component: GroupProduct,
        name:'groupProduct',
        text:'全部商品',
        meta: {
            keepAlive: true
        }
    },
    {
        path: basePath + '/freightPrice', //费率结算
        component: FreightPrice,
        name:'freightPrice',
        text:'费率结算'
    },
    {
        path: basePath + '/superGroup/groupOrder', //商品管理/订单管理
        component: GroupOrder,
        meta: {
            keepAlive: true
        },
        name:'groupOrder',
        text:'订单管理'
    },
    {
        path: basePath + '/groupConsignment/:sn?', //超团发货
        component: GroupConsignment,
        name:'groupConsignment',
        text:'超团发货'
    },
    {
        path: basePath + '/addEnterprise', //企业审核
        component: () => import('~/views/superGroup/addEnterprise'),
        name:'addEnterprise',
        text:'新增企业'
    },
    {
        path: basePath + '/groupCompanyAudit', //企业审核
        component: GroupCompanyAudit,
        meta: {
            keepAlive: true
        },
        name:'groupCompanyAudit',
        text:'企业审核'
    },
    {
        path: basePath + '/groupCommodityAudit', //下级商户商品审核
        component: GroupCommodityAudit,
        meta: {
            keepAlive: true
        },
        name:'groupCommodityAudit',
        text:'下级商户商品审核'
    },
    {
        path: basePath + '/superGroup/groupStatement', //超团报表
        component: GroupStatement,
        meta: {
            keepAlive: true
        },
        name:'groupStatement',
        text:'超团报表'
    },
    {
        path: basePath + '/superGroup/inventory', //超团库存
        component: Inventory,
        meta: {
            keepAlive: true
        },
        name:'inventory',
        text:'超团库存'
    },
    {
        path: basePath + '/superGroup/sellerManager', //商家管理
        component: SellerManager,
        meta: {
            keepAlive: true
        },
        name:'sellerManager',
        text:'商家管理'
    },
    {
        path: basePath + '/superGroup/groupManager', //团购管理
        component: GroupManager,
        meta: {
            keepAlive: true
        },
        name:'groupManager',
        text:'团购管理'
    },
    {
        path: basePath + '/superGroup/addGroup', //团购管理
        component: () => import('~/views/superGroup/addGroup'),
        name:'addGroup',
        text:'新增团购'
    },
    {
        path: basePath + '/superGroup/bondManager', //保证金管理
        component: BondManager,
        meta: {
            keepAlive: true
        },
        name:'bondManager',
        text:'保证金管理'
    },
    {
        path: basePath + '/superGroup/groupSet', //团购设置
        component: GroupSet,
        meta: {
            keepAlive: true
        },
        name:'groupSet',
        text:'团购设置'
    },
    {
        path: basePath + '/superGroup/groupAllGoods', //超团总商品
        component: GroupAllGoods,
        meta: {
            keepAlive: true
        },
        name:'groupAllGoods',
        text:'超团总商品'
    },
    {
        path: basePath + '/superGroup/groupDetail', //团购详情
        component: GroupDetail,
        meta: {
            keepAlive: true
        },
        name:'groupDetail',
        text:'团购详情'
    },
    {
        path: basePath + '/groupStatementDetail/:id?', //团购详情详情
        component: GroupStatementDetail,
        name:'groupStatementDetail',
        text:'团购详情详情'
    },
    {
        path: basePath + '/superGroup/groupSalesManager', //超团管理
        component: GroupSalesManager,
        name:'groupSalesManager',
        text:'超团管理'
    },
    {
        path: basePath +'/sf/home', // 份额管理-首页
        component: () => import('~/views/official/sharemanage/home.vue'),
        name:'home',
        text:'首页'
    },
    {
        path: basePath +'/sf/bid', // 份额管理-标的管理
        component: () => import('~/views/official/sharemanage/bid.vue'),
        name:'bid',
        text:'标的管理'
    },
    {
        path:basePath + '/sf/possessor', // 份额管理-投资人管理
        component: () => import('~/views/official/sharemanage/possessor.vue'),
        name:'possessor',
        text:'投资人管理'
    },
    {
        path: basePath +'/sf/notice', // 份额管理-通知管理
        component: () => import('~/views/official/sharemanage/notice.vue'),
        name:'notice',
        text:'通知管理'
    },
    {
        path: basePath +'/sf/bid/sjpeople', // 份额管理-标的管理-配置实缴人
        component: () => import('~/views/official/sharemanage/sjpeople.vue'),
        name:'sjpeople',
        text:'配置实缴人'
    },
    {
        path: basePath +'/sf/bid/bidinfo', // 份额管理-标的管理-新增编辑标的
        component: () => import('~/views/official/sharemanage/bidinfo.vue'),
        name:'bidinfo',
        text:'新增编辑标的'
    },
];

export const ctCarLogoRouters = [];